import React, { useState, CSSProperties, useRef, useEffect } from "react"
import { Frame } from "framer"

const PASSWORD = "Kashmir2025"

export function ProtectedHTML() {
    const [input, setInput] = useState("")
    const [authenticated, setAuthenticated] = useState(false)
    const [error, setError] = useState(false)
    const [contentHeight, setContentHeight] = useState(1200)

    const contentRef = useRef(null)

    const htmlContent = `
        <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>SDET Candidates for Kashmir Intelligence</title>
    <style>
        body {
            font-family: 'Segoe UI', Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f5f5f5;
            color: #1a1a3a;
        }
        
        .slide {
            width: 100%;
            max-width: 1024px;
            margin: 20px auto 20px auto;
            background-color: white;
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
            border-radius: 8px;
            overflow: hidden;
            page-break-after: always;
        }
        
        .slide-header {
            background-color: #1a1a3a;
            color: white;
            padding: 20px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        
        .logo {
            display: flex;
            align-items: center;
        }
        
        .logo-circle {
            width: 40px;
            height: 40px;
            background-color: #ff007a;
            border-radius: 50%;
            margin-right: 15px;
            position: relative;
            overflow: hidden;
        }
        
        .logo-circle::after {
            content: "";
            position: absolute;
            width: 5px;
            height: 60px;
            background-color: white;
            left: 8px;
            top: 0;
        }
        
        .company-name {
            font-size: 28px;
            font-weight: 600;
            color: #ff007a;
        }
        
        .slide-title {
            font-size: 22px;
            font-weight: 700;
            margin-top: 10px;
            color: white;
        }
        
        .slide-content {
            padding: 30px 40px;
        }
        
        .candidate {
            display: flex;
            margin-bottom: 30px;
            border-bottom: 1px solid #eee;
            padding-bottom: 20px;
        }
        
        .candidate-photo {
            width: 120px;
            height: 120px;
            border-radius: 60px;
            background-color: #eaeaea;
            margin-right: 25px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            color: #666;
            overflow: hidden;
        }
        
        .candidate-details {
            flex-grow: 1;
        }
        
        .candidate-name {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 5px;
            color: #1a1a3a;
        }
        
        .candidate-title {
            font-size: 18px;
            color: #ff007a;
            margin-bottom: 15px;
        }
        
        .section-title {
            font-size: 16px;
            font-weight: 600;
            margin: 15px 0 5px 0;
            color: #1a1a3a;
        }
        
        .skills-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: 10px;
        }
        
        .skill-tag {
            background-color: #f0f0ff;
            color: #1a1a3a;
            border-radius: 4px;
            padding: 4px 12px;
            font-size: 14px;
        }
        
        .experience-item {
            margin-bottom: 12px;
        }
        
        .company {
            font-weight: 600;
        }
        
        .position {
            font-style: italic;
            color: #555;
        }
        
        .responsibilities {
            margin-top: 5px;
            line-height: 1.4;
        }
        
        .footer {
            background-color: #f5f5f5;
            padding: 15px 40px;
            color: #666;
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }
        
        .cta-button {
            background-color: #ff007a;
            color: white;
            border: none;
            padding: 12px 24px;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            margin-top: 20px;
            font-weight: 500;
        }
        
        .summary-slide .slide-content {
            padding: 40px;
        }
        
        .comparison-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
            margin-top: 30px;
        }
        
        .comparison-card {
            background-color: #f8f8ff;
            padding: 20px;
            border-radius: 8px;
        }
        
        .comparison-name {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
            color: #1a1a3a;
        }
        
        .comparison-rating {
            display: flex;
            align-items: center;
            margin: 10px 0;
        }
        
        .rating-label {
            width: 180px;
            font-size: 14px;
        }
        
        .rating-bar {
            height: 8px;
            background-color: #eaeaea;
            flex-grow: 1;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
        }
        
        .rating-fill {
            position: absolute;
            height: 100%;
            background-color: #ff007a;
        }
        
        .ranking-number {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #ff007a;
            color: white;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            font-weight: bold;
            font-size: 14px;
        }

        .requirement-match {
            background-color: #f8f8ff;
            border-radius: 8px;
            padding: 20px;
            margin-top: 20px;
        }

        .match-title {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        .fit-scale {
            margin-top: 5px;
        }

        .highlight {
            background-color: #fff2f8;
            padding: 12px;
            border-radius: 6px;
            border-left: 3px solid #ff007a;
            margin: 15px 0;
        }

        .highlight-title {
            font-weight: 600;
            color: #ff007a;
            margin-bottom: 5px;
        }
        
        .rank-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
        }

        .rank-card {
            display: flex;
            padding: 15px;
            background-color: #f8f8ff;
            border-radius: 8px;
            position: relative;
        }

        .rank-photo {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background-color: #eaeaea;
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: #666;
        }

        .rank-details {
            flex-grow: 1;
        }

        .rank-name {
            font-size: 18px;
            font-weight: 600;
            color: #1a1a3a;
        }

        .rank-title {
            font-size: 14px;
            color: #ff007a;
        }
        
        .top-pick {
            position: absolute;
            top: -10px;
            right: -10px;
            background-color: #ff007a;
            color: white;
            padding: 5px 12px;
            border-radius: 15px;
            font-weight: bold;
            font-size: 12px;
            z-index: 1;
        }
        
        .secondary-candidate .candidate-photo {
            width: 100px;
            height: 100px;
            font-size: 36px;
        }
        
        .secondary-candidate .candidate-name {
            font-size: 22px;
        }
        
        .secondary-candidate .candidate-title {
            font-size: 16px;
        }
        
        .secondary-candidate .requirement-match .comparison-rating .rating-label {
            width: 150px;
            font-size: 13px;
        }
        
        .two-column-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 30px;
        }
    </style>
</head>
<body>
    <!-- Title Slide -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div>April 2025</div>
        </div>
        <div class="slide-content" style="text-align: center; padding: 80px 40px;">
            <h1 style="font-size: 32px; margin-bottom: 20px; color: #1a1a3a;">Distributed SDET Candidates</h1>
            <h2 style="font-size: 24px; font-weight: 400; color: #ff007a; margin-bottom: 60px;">AI Platform Quality & Test Automation</h2>
            <p style="font-size: 18px; max-width: 700px; margin: 0 auto 30px; line-height: 1.6;">
                Carefully selected senior engineers with specific expertise matching Kashmir Intelligence's requirements for building testing infrastructure for AI platforms.
            </p>
            <div style="width: 80%; margin: 0 auto; text-align: left; background-color: #f8f8ff; padding: 20px; border-radius: 8px;">
                <div style="font-weight: 600; font-size: 18px; margin-bottom: 15px;">Key Requirements</div>
                <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px;">
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>Python/JS Testing (pytest, Playwright)</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>CI/CD Pipeline Integration</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>API, Performance (k6), UI Testing</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>Security Testing (OWASP/Snyk)</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>ML/AI Systems Experience</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>Kubernetes & Docker</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>Quality Metrics Tools</div>
                    </div>
                    <div style="display: flex; align-items: center;">
                        <div style="width: 8px; height: 8px; background-color: #ff007a; border-radius: 4px; margin-right: 10px;"></div>
                        <div>Building Test Infrastructure</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>1</div>
        </div>
    </div>
    
    <!-- Top Picks Overview Slide -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Recommended Candidates</div>
        </div>
        <div class="slide-content">
            <p style="margin-bottom: 30px;">Based on an in-depth analysis of Kashmir Intelligence's requirements, we've identified these top candidates as excellent matches for the AI Platform Quality & Test Automation role:</p>
            
            <div class="rank-list">
                <!-- Top pick 1 -->
                <div class="rank-card" style="position: relative;">
                    <div class="rank-photo" style="background-color: #fff2f8;">A</div>
                    <div class="rank-details">
                        <div class="rank-name">Aniket N.</div>
                        <div class="rank-title">Lead Software Engineer in Test (AI/ML)</div>
                        <p style="margin-top: 8px;">Direct AI content validation testing experience, NLP frameworks, ML workflow testing.</p>
                        <p style="font-style: italic; color: #666; margin: 5px 0;">Location: India (Remote)</p>
                        <div style="display: flex; gap: 8px; margin-top: 10px;">
                            <div style="font-size: 12px; background-color: #fff2f8; color: #ff007a; padding: 3px 8px; border-radius: 4px;">AI/ML: 95%</div>
                            <div style="font-size: 12px; background-color: #fff2f8; color: #ff007a; padding: 3px 8px; border-radius: 4px;">Python: 95%</div>
                            <div style="font-size: 12px; background-color: #fff2f8; color: #ff007a; padding: 3px 8px; border-radius: 4px;">k6: 95%</div>
                        </div>
                    </div>
                </div>
                
                <!-- Top pick 2 -->
                <div class="rank-card">
                    <div class="rank-photo">R</div>
                    <div class="rank-details">
                        <div class="rank-name">Raja P.</div>
                        <div class="rank-title">Senior SDET (AI/ML)</div>
                        <p style="margin-top: 8px;">ML analytics platform testing, ETL processes, AWS Lambda, Terraform.</p>
                        <p style="font-style: italic; color: #666; margin: 5px 0;">Location: India (Remote)</p>
                        <div style="display: flex; gap: 8px; margin-top: 10px;">
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">AI/ML: 90%</div>
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">Python: 90%</div>
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">Security: 85%</div>
                        </div>
                    </div>
                </div>
                
                <!-- Pick 3 -->
                <div class="rank-card">
                    <div class="rank-photo">M</div>
                    <div class="rank-details">
                        <div class="rank-name">Mahesh T.</div>
                        <div class="rank-title">SDET - DevOps/Platform Engineer</div>
                        <p style="margin-top: 8px;">K6 performance framework, Playwright, AWS/Kubernetes expertise.</p>
                        <p style="font-style: italic; color: #666; margin: 5px 0;">Location: United Kingdom (Remote)</p>
                        <div style="display: flex; gap: 8px; margin-top: 10px;">
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">Infrastructure: 95%</div>
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">CI/CD: 95%</div>
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">K8s: 95%</div>
                        </div>
                    </div>
                </div>
                
                <!-- Pick 4 -->
                <div class="rank-card">
                    <div class="rank-photo">V</div>
                    <div class="rank-details">
                        <div class="rank-name">Vikas C.</div>
                        <div class="rank-title">SDET/QA/Test Automation Architect</div>
                        <p style="margin-top: 8px;">Performance testing tools, AWS S3, distributed system testing.</p>
                        <p style="font-style: italic; color: #666; margin: 5px 0;">Location: India (Remote)</p>
                        <div style="display: flex; gap: 8px; margin-top: 10px;">
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">Architecture: 95%</div>
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">Open-Source: 90%</div>
                            <div style="font-size: 12px; background-color: #f0f0ff; padding: 3px 8px; border-radius: 4px;">Python: 85%</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div style="margin-top: 30px; background-color: #fff2f8; padding: 20px; border-radius: 8px;">
                <div style="font-weight: 600; margin-bottom: 10px;">Why Aniket N. is a Top Recommendation</div>
                <p>
                    Aniket has direct, hands-on experience with AI content validation testing and ML workflows, closely aligning with Kashmir Intelligence's core requirements. His experience developing NLP-based test frameworks for AI-generated content and creating synthetic data for ML testing makes him uniquely qualified for this role. His background with k6 performance testing and GitHub Actions CI/CD integration provides a complete skill set for building a testing infrastructure from scratch.
                </p>
            </div>
            
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>2</div>
        </div>
    </div>
    
    <!-- Candidate 1: Aniket N. -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Top Candidate Profile</div>
        </div>
        <div class="slide-content">
            <div class="candidate">
                <div class="candidate-photo">A</div>
                <div class="candidate-details">
                    <div class="candidate-name">Aniket N.</div>
                    <div class="candidate-title">Lead Software Engineer in Test (AI/ML)</div>
                    <p style="margin-bottom: 5px;">Technical leader with nearly a decade of experience in QA Engineering, specializing in AI/ML quality assurance. Expert in developing Python-based automation frameworks for data science products and ML workflows.</p>
                    <p style="font-style: italic; color: #666;">Location: India (Remote)</p>
                </div>
            </div>
            
            <div class="highlight">
                <div class="highlight-title">Match for Kashmir Intelligence</div>
                <div>Direct experience in AI content validation testing, NLP-based test frameworks, and end-to-end ML workflow testing closely aligning with the core requirements.</div>
            </div>
            
            <div class="section-title">Key Skills</div>
            <div class="skills-container">
                <div class="skill-tag">Python (pytest, BDD)</div>
                <div class="skill-tag">AI/ML Testing</div>
                <div class="skill-tag">K6 Performance</div>
                <div class="skill-tag">GitHub Actions CI/CD</div>
                <div class="skill-tag">AWS Cloud</div>
                <div class="skill-tag">NLP Testing Techniques</div>
                <div class="skill-tag">Synthetic ML Data</div>
                <div class="skill-tag">Docker/Kubernetes</div>
            </div>
            
            <div class="section-title">Recent Experience</div>
            <div class="experience-item">
                <div class="company">JustAnswer (2023-Present)</div>
                <div class="position">Lead Software Engineer in Test (AI/ML)</div>
                <div class="responsibilities">
                    Developed an AI-powered content validation framework using NLP techniques to test and evaluate AI-generated responses, ensuring model accuracy and reliability. Created synthetic data generation strategies for ML workflow testing. Built end-to-end testing infrastructure for AI-driven features.
                </div>
            </div>
            <div class="experience-item">
                <div class="company">Indeed (2021-2023)</div>
                <div class="position">Staff SDET | Technical Leader</div>
                <div class="responsibilities">
                    Developed a Python/Pytest API automation framework supporting GraphQL, gRPC, REST, and event-driven services. Achieved 95% automation coverage across all services, reducing delivery lead time significantly. Built and optimized a K6 performance testing framework.
                </div>
            </div>
            
            <div class="requirement-match">
                <div class="match-title">Kashmir Intelligence Requirements Match</div>
                <div class="comparison-rating">
                    <div class="rating-label">Python/JS Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">CI/CD Integration</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">API & Performance Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">ML/AI Systems Experience</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Kubernetes & Docker</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Test Infrastructure Building</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Security Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 75%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Quality Metrics Tools</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                </div>
            </div>
            
     
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>3</div>
        </div>
    </div>
    
    <!-- Candidate 2: Raja P. -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Recommended Candidate Profile</div>
        </div>
        <div class="slide-content">
            <div class="candidate">
                <div class="candidate-photo">R</div>
                <div class="candidate-details">
                    <div class="candidate-name">Raja P.</div>
                    <div class="candidate-title">Senior Software Development Engineer in Test (AI/ML)</div>
                    <p style="margin-bottom: 5px;">Expert in automation testing for AI/ML platforms with government security clearance. Specializes in ETL, machine learning analytics, and building robust automated testing frameworks for complex data platforms.</p>
                    <p style="font-style: italic; color: #666;">Location: India (Remote)</p>
                </div>
            </div>
            
            <div class="highlight">
                <div class="highlight-title">Match for Kashmir Intelligence</div>
                <div>Strong experience with ETL and machine learning analytics testing, AWS Lambda, and building secure test infrastructure for AI-driven applications.</div>
            </div>
            
            <div class="section-title">Key Skills</div>
            <div class="skills-container">
                <div class="skill-tag">Python Automation</div>
                <div class="skill-tag">AI/ML Analytics Testing</div>
                <div class="skill-tag">AWS Lambda/Cloud</div>
                <div class="skill-tag">BDD Frameworks</div>
                <div class="skill-tag">GitLab CI/CD</div>
                <div class="skill-tag">Terraform/IaC</div>
                <div class="skill-tag">Security Testing</div>
                <div class="skill-tag">Docker/Kubernetes</div>
            </div>
            
            <div class="section-title">Recent Experience</div>
            <div class="experience-item">
                <div class="company">Kainos (2021-Present)</div>
                <div class="position">Software Development Engineer in Test</div>
                <div class="responsibilities">
                    Designed and developed a parallel scalable test framework using Python, Fixtures, Lambda Layers, and Behave to ensure robust automation for a complex ETL and machine learning analytics platform. Implemented CI/CD processes using GitLab, AWS Secrets Manager, and built test infrastructure using Terraform.
                </div>
            </div>
            <div class="experience-item">
                <div class="company">Retrace.ai (2024-Present)</div>
                <div class="position">Software Development Engineer in Test</div>
                <div class="responsibilities">
                    Working on an AI-based dental claims automation platform, designing and implementing test automation frameworks for complex application functionalities. Applied BDD approaches for comprehensive API and database testing of AI systems. Managed MongoDB collections for efficient data handling.
                </div>
            </div>
            
            <div class="requirement-match">
                <div class="match-title">Kashmir Intelligence Requirements Match</div>
                <div class="comparison-rating">
                    <div class="rating-label">Python/JS Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">CI/CD Integration</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">API & Performance Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">ML/AI Systems Experience</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Kubernetes & Docker</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Test Infrastructure Building</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Security Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Quality Metrics Tools</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 75%;"></div></div>
                </div>
            </div>
            
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>4</div>
        </div>
    </div>
    
    <!-- Candidate 3: Mahesh T. -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Recommended Candidate Profile</div>
        </div>
        <div class="slide-content">
            <div class="candidate">
                <div class="candidate-photo">M</div>
                <div class="candidate-details">
                    <div class="candidate-name">Mahesh T.</div>
                    <div class="candidate-title">SDET - DevOps/Platform Engineer</div>
                    <p style="margin-bottom: 5px;">Seasoned QA Lead with over a decade of expertise in Quality Engineering, DevOps, infrastructure, and platform engineering. Specialist in developing test automation frameworks with strong cloud experience.</p>
                    <p style="font-style: italic; color: #666;">Location: United Kingdom (Remote)</p>
                </div>
            </div>
            
            <div class="highlight">
                <div class="highlight-title">Match for Kashmir Intelligence</div>
                <div>Outstanding experience in creating performance testing frameworks, infrastructure as code, and CI/CD pipelines for microservices architecture.</div>
            </div>
            
            <div class="section-title">Key Skills</div>
            <div class="skills-container">
                <div class="skill-tag">JavaScript/TypeScript</div>
                <div class="skill-tag">Python</div>
                <div class="skill-tag">Playwright</div>
                <div class="skill-tag">K6 Performance</div>
                <div class="skill-tag">AWS/GCP/Azure</div>
                <div class="skill-tag">Kubernetes</div>
                <div class="skill-tag">CI/CD Pipelines</div>
                <div class="skill-tag">Terraform/IaC</div>
            </div>
            
            <div class="section-title">Recent Experience</div>
            <div class="experience-item">
                <div class="company">15 Marketing (2023-Present)</div>
                <div class="position">DevOps/Platform Engineer/SDET</div>
                <div class="responsibilities">
                    Designed AWS infrastructure using Terraform for gaming platforms. Developed a performance testing framework with K6 and established load testing for microservices in AWS. Implemented infrastructure monitoring and deployed containerized applications for development environments.
                </div>
            </div>
            <div class="experience-item">
                <div class="company">HMRC (2022-2023)</div>
                <div class="position">Senior SDET</div>
                <div class="responsibilities">
                    Led quality assurance initiatives for a UK Border Force investigations platform. Implemented automation frameworks for Angular applications and APIs using Playwright, TypeScript, and Python. Collaborated with SRE teams on chaos testing and worked with DevOps on AWS service deployment.
                </div>
            </div>
            
            <div class="requirement-match">
                <div class="match-title">Kashmir Intelligence Requirements Match</div>
                <div class="comparison-rating">
                    <div class="rating-label">Python/JS Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">CI/CD Integration</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">API & Performance Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">ML/AI Systems Experience</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 70%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Kubernetes & Docker</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Test Infrastructure Building</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Security Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Quality Metrics Tools</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
            </div>
            
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>5</div>
        </div>
    </div>
    
    <!-- Candidate 4: Vikas C. -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Recommended Candidate Profile</div>
        </div>
        <div class="slide-content">
            <div class="candidate">
                <div class="candidate-photo">V</div>
                <div class="candidate-details">
                    <div class="candidate-name">Vikas C.</div>
                    <div class="candidate-title">SDET/QA/Test Automation Architect</div>
                    <p style="margin-bottom: 5px;">Seasoned test architect with 17+ years of experience in automation frameworks, storage/cloud platforms, and open-source development. Expert in creating test strategies for complex distributed systems.</p>
                    <p style="font-style: italic; color: #666;">Location: India (Remote)</p>
                </div>
            </div>
            
            <div class="highlight">
                <div class="highlight-title">Match for Kashmir Intelligence</div>
                <div>Extensive experience with open-source test automation, S3 protocol testing, and creating performance/stability testing tools for complex distributed systems.</div>
            </div>
            
            <div class="section-title">Key Skills</div>
            <div class="skills-container">
                <div class="skill-tag">Python, Go, Java</div>
                <div class="skill-tag">AWS S3 Testing</div>
                <div class="skill-tag">Open-Source Infrastructure</div>
                <div class="skill-tag">Performance Testing</div>
                <div class="skill-tag">Docker/Kubernetes</div>
                <div class="skill-tag">CI/CD Pipelines</div>
                <div class="skill-tag">Stability Testing</div>
                <div class="skill-tag">Test Architecture</div>
            </div>
            
            <div class="section-title">Recent Experience</div>
            <div class="experience-item">
                <div class="company">Seagate Technologies (2021-Present)</div>
                <div class="position">Staff Engineer (Lead Roles as Architect)</div>
                <div class="responsibilities">
                    Led architecture, design, and development of testing for open-source storage/cloud projects (CORTX, Lyve Cloud). Developed Python-based automation frameworks and tools for S3 protocol testing. Created a continuous load generator tool for IO stability and stress/performance testing.
                </div>
            </div>
            <div class="experience-item">
                <div class="company">MSys Technologies (2019-2021)</div>
                <div class="position">Engineering Manager (Automation, Leader, Architect)</div>
                <div class="responsibilities">
                    Managed automation framework development for Elastic Object Storage, specializing in NFS protocol testing with a team of 10 engineers. Designed testing infrastructure and implemented comprehensive test strategies for storage systems.
                </div>
            </div>
            
            <div class="requirement-match">
                <div class="match-title">Kashmir Intelligence Requirements Match</div>
                <div class="comparison-rating">
                    <div class="rating-label">Python/JS Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">CI/CD Integration</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">API & Performance Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">ML/AI Systems Experience</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 75%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Kubernetes & Docker</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Test Infrastructure Building</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 95%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Security Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Quality Metrics Tools</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
            </div>
            
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>6</div>
        </div>
    </div>
    
    <!-- Candidate 5: Prachi Z. -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Additional Candidate Profile</div>
        </div>
        <div class="slide-content">
            <div class="candidate">
                <div class="candidate-photo">P</div>
                <div class="candidate-details">
                    <div class="candidate-name">Prachi Z.</div>
                    <div class="candidate-title">Senior Software QA Professional | Lead Engineer</div>
                    <p style="margin-bottom: 5px;">Accomplished QA leader with 15+ years of experience in quality engineering, test automation, and team leadership. Specializes in building scalable test frameworks and implementing advanced testing methodologies.</p>
                    <p style="font-style: italic; color: #666;">Location: United Kingdom (Remote)</p>
                </div>
            </div>
            
            <div class="highlight">
                <div class="highlight-title">Match for Kashmir Intelligence</div>
                <div>Strong leadership background with recent experience integrating AI-enhanced testing approaches and managing large engineering teams, valuable for scaling testing capabilities.</div>
            </div>
            
            <div class="section-title">Key Skills</div>
            <div class="skills-container">
                <div class="skill-tag">AI-Enhanced Testing</div>
                <div class="skill-tag">Team Leadership (50+)</div>
                <div class="skill-tag">Python, JS, Java</div>
                <div class="skill-tag">Performance Testing</div>
                <div class="skill-tag">Cloud Testing (AWS)</div>
                <div class="skill-tag">Security Testing</div>
                <div class="skill-tag">Test Strategy</div>
                <div class="skill-tag">CI/CD Integration</div>
            </div>
            
            <div class="section-title">Recent Experience</div>
            <div class="experience-item">
                <div class="company">Infosys (2023-Present)</div>
                <div class="position">Lead QA Consultant</div>
                <div class="responsibilities">
                    Led a 50-member team overseeing product delivery and operations for clients including Barclays Bank and Peloton. Developed test automation strategies and integrated AWS services (S3) into automation frameworks. Implemented AI-enhanced API testing for contract validation.
                </div>
            </div>
            <div class="experience-item">
                <div class="company">Concirrus Limited (2022-2023)</div>
                <div class="position">Principal SDET & Manager</div>
                <div class="responsibilities">
                    Delivered end-to-end testing solutions for front-end UI and API, led performance testing of backend infrastructure, and architected UI/AP automation frameworks from scratch. Mentored and coached a team of 5 QA engineers.
                </div>
            </div>
            
            <div class="requirement-match">
                <div class="match-title">Kashmir Intelligence Requirements Match</div>
                <div class="comparison-rating">
                    <div class="rating-label">Python/JS Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">CI/CD Integration</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">API & Performance Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">ML/AI Systems Experience</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 75%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Kubernetes & Docker</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Test Infrastructure Building</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Security Testing</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                </div>
                <div class="comparison-rating">
                    <div class="rating-label">Quality Metrics Tools</div>
                    <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                </div>
            </div>
            
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>7</div>
        </div>
    </div>
    
    <!-- Additional Candidates Grid: Candidates 6-10 -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Additional SDET Candidates</div>
        </div>
        <div class="slide-content">
            <p style="margin-bottom: 20px;">The following candidates also possess relevant skills, though with less direct alignment to Kashmir Intelligence's specific AI/ML testing requirements:</p>
            
            <div class="two-column-grid">
                <!-- Candidate 6: Emrah K. -->
                <div class="secondary-candidate">
                    <div style="display: flex; border-bottom: 1px solid #eee; padding-bottom: 15px; margin-bottom: 15px;">
                        <div class="candidate-photo">E</div>
                        <div>
                            <div class="candidate-name">Emrah K.</div>
                            <div class="candidate-title">Software Development Engineer in Test</div>
                            <p style="font-style: italic; color: #666; margin: 5px 0;">Turkey (Remote)</p>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Key Skills</div>
                                <div class="skills-container">
                                    <div class="skill-tag">Java Automation</div>
                                    <div class="skill-tag">Python/JavaScript</div>
                                    <div class="skill-tag">Selenium WebDriver</div>
                                    <div class="skill-tag">API Testing</div>
                                    <div class="skill-tag">Cybersecurity</div>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Recent Experience</div>
                                iHerb (2022-2024): Created Python-Behave automation framework for e-commerce systems<br>
                                thredd.com (2024): Developed financial system test automation with C#
                            </div>
                        </div>
                    </div>
                    
                    <div class="requirement-match" style="padding: 15px; margin-top: 0;">
                        <div class="match-title" style="margin-bottom: 10px; font-size: 14px;">Requirements Match</div>
                        <div class="comparison-rating">
                            <div class="rating-label">Python/JS Testing</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 80%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">ML/AI Systems Experience</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 40%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">Test Infrastructure</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 75%;"></div></div>
                        </div>
                    </div>
                </div>
                
                <!-- Candidate 7: Edward D. -->
                <div class="secondary-candidate">
                    <div style="display: flex; border-bottom: 1px solid #eee; padding-bottom: 15px; margin-bottom: 15px;">
                        <div class="candidate-photo">E</div>
                        <div>
                            <div class="candidate-name">Edward D.</div>
                            <div class="candidate-title">QA Automation Engineer</div>
                            <p style="font-style: italic; color: #666; margin: 5px 0;">United Kingdom (Remote)</p>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Key Skills</div>
                                <div class="skills-container">
                                    <div class="skill-tag">Python Automation</div>
                                    <div class="skill-tag">Security Testing</div>
                                    <div class="skill-tag">Compliance Testing</div>
                                    <div class="skill-tag">Cloud Infrastructure</div>
                                    <div class="skill-tag">API Testing</div>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Recent Experience</div>
                                ControlUp (2024): Edge computing platform testing based on Azure DevOps<br>
                                Citrix (2023-2024): Designed Python tests for hypervisor/VDI products
                            </div>
                        </div>
                    </div>
                    
                    <div class="requirement-match" style="padding: 15px; margin-top: 0;">
                        <div class="match-title" style="margin-bottom: 10px; font-size: 14px;">Requirements Match</div>
                        <div class="comparison-rating">
                            <div class="rating-label">Python/JS Testing</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">ML/AI Systems Experience</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 35%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">Security Testing</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                        </div>
                    </div>
                </div>
                
                <!-- Candidate 8: Sachin M. -->
                <div class="secondary-candidate">
                    <div style="display: flex; border-bottom: 1px solid #eee; padding-bottom: 15px; margin-bottom: 15px;">
                        <div class="candidate-photo">S</div>
                        <div>
                            <div class="candidate-name">Sachin M.</div>
                            <div class="candidate-title">Senior Engineer</div>
                            <p style="font-style: italic; color: #666; margin: 5px 0;">United Kingdom (Remote)</p>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Key Skills</div>
                                <div class="skills-container">
                                    <div class="skill-tag">Java/C#/Python</div>
                                    <div class="skill-tag">Microservices</div>
                                    <div class="skill-tag">API Testing</div>
                                    <div class="skill-tag">Event-Driven Testing</div>
                                    <div class="skill-tag">CI/CD Integration</div>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Recent Experience</div>
                                GSK (2021-Present): Developed framework for containerized test environments in AKS<br>
                                PWC (2021): Lead test automation consultant for AI-powered cash audit
                            </div>
                        </div>
                    </div>
                    
                    <div class="requirement-match" style="padding: 15px; margin-top: 0;">
                        <div class="match-title" style="margin-bottom: 10px; font-size: 14px;">Requirements Match</div>
                        <div class="comparison-rating">
                            <div class="rating-label">Python/JS Testing</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 75%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">ML/AI Systems Experience</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 60%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">Infrastructure</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                        </div>
                    </div>
                </div>
                
                <!-- Candidate 9: Dario C. -->
                <div class="secondary-candidate">
                    <div style="display: flex; border-bottom: 1px solid #eee; padding-bottom: 15px; margin-bottom: 15px;">
                        <div class="candidate-photo">D</div>
                        <div>
                            <div class="candidate-name">Dario C.</div>
                            <div class="candidate-title">Senior SDET</div>
                            <p style="font-style: italic; color: #666; margin: 5px 0;">Croatia (Remote)</p>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Key Skills</div>
                                <div class="skills-container">
                                    <div class="skill-tag">Playwright</div>
                                    <div class="skill-tag">Web/Mobile Testing</div>
                                    <div class="skill-tag">TypeScript/JavaScript</div>
                                    <div class="skill-tag">Web3 Testing</div>
                                    <div class="skill-tag">CI/CD Pipelines</div>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Recent Experience</div>
                                Helio Fintech (2024): Developed Playwright framework for web3 applications<br>
                                Toco AG (2024): Created automation for blockchain payments and wallets
                            </div>
                        </div>
                    </div>
                    
                    <div class="requirement-match" style="padding: 15px; margin-top: 0;">
                        <div class="match-title" style="margin-bottom: 10px; font-size: 14px;">Requirements Match</div>
                        <div class="comparison-rating">
                            <div class="rating-label">Python/JS Testing</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 90%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">ML/AI Systems Experience</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 30%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">Test Framework</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 85%;"></div></div>
                        </div>
                    </div>
                </div>
                
                <!-- Candidate 10: Shubham C. -->
                <div class="secondary-candidate">
                    <div style="display: flex; border-bottom: 1px solid #eee; padding-bottom: 15px; margin-bottom: 15px;">
                        <div class="candidate-photo">S</div>
                        <div>
                            <div class="candidate-name">Shubham C.</div>
                            <div class="candidate-title">Software Engineer</div>
                            <p style="font-style: italic; color: #666; margin: 5px 0;">India (Remote)</p>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Key Skills</div>
                                <div class="skills-container">
                                    <div class="skill-tag">Java</div>
                                    <div class="skill-tag">Selenium</div>
                                    <div class="skill-tag">API Testing</div>
                                    <div class="skill-tag">SQL</div>
                                    <div class="skill-tag">Functional Testing</div>
                                </div>
                            </div>
                            <div style="margin-top: 10px;">
                                <div style="font-weight: 600; margin-bottom: 5px;">Recent Experience</div>
                                Zupee (2021-Present): Creates test cases and automates using codeless and code-based processes<br>
                                Camelot UK (2019): Created automation framework with POM in Java
                            </div>
                        </div>
                    </div>
                    
                    <div class="requirement-match" style="padding: 15px; margin-top: 0;">
                        <div class="match-title" style="margin-bottom: 10px; font-size: 14px;">Requirements Match</div>
                        <div class="comparison-rating">
                            <div class="rating-label">Python/JS Testing</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 60%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">ML/AI Systems Experience</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 25%;"></div></div>
                        </div>
                        <div class="comparison-rating">
                            <div class="rating-label">CI/CD Integration</div>
                            <div class="rating-bar"><div class="rating-fill" style="width: 70%;"></div></div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>8</div>
        </div>
    </div>
    
    <!-- Summary and Recommendation Slide -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Summary & Recommendations</div>
        </div>
        <div class="slide-content">
            <p style="margin-bottom: 30px;">After analyzing Kashmir Intelligence's requirements for an SDET specializing in AI Platform Quality & Test Automation, we recommend the following candidates:</p>
            
            <div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 20px; margin-bottom: 30px;">
                <!-- Primary Recommendation -->
                <div style="background-color: #fff2f8; padding: 20px; border-radius: 8px; grid-column: span 1;">
                    <div style="font-weight: 600; font-size: 16px; margin-bottom: 10px;">Recommendation</div>
                    <div style="display: flex; align-items: center; margin-bottom: 15px;">
                        <div style="width: 50px; height: 50px; border-radius: 25px; background-color: #eaeaea; display: flex; align-items: center; justify-content: center; margin-right: 15px;">A</div>
                        <div>
                            <div style="font-weight: 600;">Aniket N.</div>
                            <div style="color: #ff007a; font-size: 14px;">Lead Software Engineer in Test (AI/ML)</div>
                        </div>
                    </div>
                    <p style="font-size: 14px; line-height: 1.4;">
                        Direct experience with AI content validation testing, NLP frameworks, and ML workflow testing. Combines Python expertise with performance testing (k6) and CI/CD integration exactly matching requirements.
                    </p>
                </div>
                
                <!-- Secondary Recommendations -->
                <div style="background-color: #f8f8ff; padding: 20px; border-radius: 8px; grid-column: span 2;">
                    <div style="font-weight: 600; font-size: 16px; margin-bottom: 10px;">Strong Alternatives</div>
                    <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 15px;">
                        <div style="display: flex; align-items: center;">
                            <div style="width: 40px; height: 40px; border-radius: 20px; background-color: #eaeaea; display: flex; align-items: center; justify-content: center; margin-right: 10px; font-size: 14px;">R</div>
                            <div>
                                <div style="font-weight: 600;">Raja P.</div>
                                <div style="color: #555; font-size: 13px;">ML/Analytics Platform Testing</div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <div style="width: 40px; height: 40px; border-radius: 20px; background-color: #eaeaea; display: flex; align-items: center; justify-content: center; margin-right: 10px; font-size: 14px;">M</div>
                            <div>
                                <div style="font-weight: 600;">Mahesh T.</div>
                                <div style="color: #555; font-size: 13px;">Infrastructure/DevOps Focus</div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <div style="width: 40px; height: 40px; border-radius: 20px; background-color: #eaeaea; display: flex; align-items: center; justify-content: center; margin-right: 10px; font-size: 14px;">V</div>
                            <div>
                                <div style="font-weight: 600;">Vikas C.</div>
                                <div style="color: #555; font-size: 13px;">Open-Source Test Architecture</div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center;">
                            <div style="width: 40px; height: 40px; border-radius: 20px; background-color: #eaeaea; display: flex; align-items: center; justify-content: center; margin-right: 10px; font-size: 14px;">P</div>
                            <div>
                                <div style="font-weight: 600;">Prachi Z.</div>
                                <div style="color: #555; font-size: 13px;">Team Leadership & Security</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div style="background-color: #f5f5f5; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
                <div style="font-weight: 600; margin-bottom: 10px;">Key Decision Factors</div>
                <ul style="padding-left: 20px; margin: 0;">
                    <li style="margin-bottom: 8px;"><strong>Direct AI/ML Testing Experience:</strong> Aniket and Raja have the most relevant AI-specific testing experience</li>
                    <li style="margin-bottom: 8px;"><strong>Infrastructure Knowledge:</strong> Mahesh and Vikas excel in infrastructure and platform engineering</li>
                    <li style="margin-bottom: 8px;"><strong>Location Preferences:</strong> Both European (UK/Croatia) and Indian candidates are available</li>
                    <li style="margin-bottom: 8px;"><strong>Technology Specificity:</strong> All top candidates have strong Python and CI/CD integration experience</li>
                </ul>
            </div>
            
           
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>9</div>
        </div>
    </div>
    
    <!-- Contact Slide -->
    <div class="slide">
        <div class="slide-header">
            <div class="logo">
                <div class="logo-circle"></div>
                <div class="company-name">Distributed</div>
            </div>
            <div class="slide-title">Next Steps</div>
        </div>
        <div class="slide-content" style="text-align: center; padding: 60px 40px;">
            <h2 style="font-size: 28px; color: #1a1a3a; margin-bottom: 30px;">Ready to move forward?</h2>
            
            <div style="max-width: 600px; margin: 0 auto 40px; background-color: #f8f8ff; padding: 30px; border-radius: 8px;">
                <p style="font-size: 18px; margin-bottom: 30px;">Distributed is ready to facilitate interviews these candidates. We can arrange technical assessments, coordinate schedules, and help you make the best hiring decision for your AI platform testing needs.</p>
                
                <div style="display: flex; justify-content: center; gap: 30px; margin-bottom: 20px;">
                    <div style="text-align: center;">
                        <div style="width: 60px; height: 60px; border-radius: 30px; background-color: #fff2f8; display: flex; align-items: center; justify-content: center; margin: 0 auto 10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ff007a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                        </div>
                        <div style="font-weight: 600;">Schedule<br>Interviews</div>
                    </div>
                    <div style="text-align: center;">
                        <div style="width: 60px; height: 60px; border-radius: 30px; background-color: #fff2f8; display: flex; align-items: center; justify-content: center; margin: 0 auto 10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ff007a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                        </div>
                        <div style="font-weight: 600;">Technical<br>Assessment</div>
                    </div>
                    <div style="text-align: center;">
                        <div style="width: 60px; height: 60px; border-radius: 30px; background-color: #fff2f8; display: flex; align-items: center; justify-content: center; margin: 0 auto 10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ff007a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                        </div>
                        <div style="font-weight: 600;">Finalize<br>Hiring</div>
                    </div>
                </div>
            </div>
            
            <a href="mailto:simon.leach@distributed.com" class="cta-button" style="background-color: #FF0F80; color: white; padding: 15px 30px; font-size: 18px; text-decoration: none; display: inline-block; border-radius: 4px; font-family: sans-serif; text-align: center;">Contact Distributed Team</a>
        </div>
        <div class="footer">
            <div>Confidential</div>
            <div>10</div>
        </div>
    </div>
</body>
</html>
                    
    `

    const authenticateUser = () => {
        if (input === PASSWORD) {
            setAuthenticated(true)
            setError(false)
        } else {
            setError(true)
        }
    }

    // Dynamically adjust height when the content changes
    useEffect(() => {
        if (authenticated && contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight) // Capture content height
        }
    }, [authenticated])

    if (authenticated) {
        return (
            <Frame
                style={{
                    width: "100%",
                    height: `${contentHeight}px`, // Dynamically set height
                    padding: "20px",
                    backgroundColor: "#fff",
                }}
            >
                {/* Ref for height measurement */}
                <div
                    ref={contentRef}
                    dangerouslySetInnerHTML={{
                        __html: htmlContent,
                    }}
                    style={{
                        visibility: "hidden", // Make this hidden to avoid affecting layout
                        position: "absolute",
                    }}
                />
                {/* Rendered content displayed to the user */}
                <div
                    dangerouslySetInnerHTML={{
                        __html: htmlContent,
                    }}
                    style={{
                        width: "100%",
                    }}
                />
            </Frame>
        )
    }

    return (
        <Frame
            style={{
                width: "100%",
                height: `${contentHeight}px`, // Dynamically set container height
                padding: "20px",
                backgroundColor: authenticated ? "#ffffff" : "#f7f7f7",
                border: "1px solid #ddd",
                borderRadius: "8px",
                overflow: "hidden",
            }}
        >
            {!authenticated ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <h2>Please Enter Password</h2>
                    <input
                        type="password"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        style={{
                            marginTop: "10px",
                            padding: "10px",
                            width: "100%",
                            maxWidth: "300px",
                            fontSize: "16px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                        }}
                        placeholder="Enter Password"
                    />
                    <button
                        onClick={authenticateUser}
                        style={{
                            marginTop: "20px",
                            padding: "10px 20px",
                            backgroundColor: "#007bff",
                            color: "#fff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                        }}
                    >
                        Submit
                    </button>
                    {error && (
                        <p style={{ color: "red", marginTop: "10px" }}>
                            Incorrect password. Please try again.
                        </p>
                    )}
                </div>
            ) : (
                <div
                    ref={containerRef} // Reference for dynamic height calculation
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                    style={{
                        width: "100%", // Ensure full width
                    }}
                />
            )}
        </Frame>
    )
}

const styles: Record<string, CSSProperties> = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#fff",
    },
    card: {
        padding: "20px",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
        textAlign: "center" as "center", // Explicitly define textAlign
        width: "300px",
    },
    title: {
        fontFamily: "Poppins, sans-serif",
        marginBottom: "15px",
        color: "#333",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    input: {
        padding: "10px",
        marginBottom: "20px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        fontSize: "14px",
        fontFamily: "Poppins, sans-serif",
    },
    button: {
        padding: "10px",
        backgroundColor: "#ff007a",
        color: "#fff",
        border: "none",
        borderRadius: "25px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Poppins, sans-serif",
    },
    error: {
        marginTop: "10px",
        color: "#D8512A",
        fontFamily: "Poppins, sans-serif",
    },
}
